<template>
  <FormModal title="Verpakkingsvoorwaarden" show-back-btn>
    <template #body>
      <PackingConditionsAssemblyDateFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import PackingConditionsAssemblyDateFormStep from '~/components/form/steps/packingConditionsAssemblyDate/PackingConditionsAssemblyDateFormStep'

export default {
  components: {
    PackingConditionsAssemblyDateFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Verhuis details',
      headDescription: 'Geef de details voor de verhuizing aan.',
      path: '/offertes-aanvragen/verhuis-details',
      progressValue: 60,
      checkoutStep: 3,
    }
  },
}
</script>
